function Chip({ item, removeChip}) {
    return (
        <div className="chip">
            <span>{item}</span>
            <span className="close_btn" onClick={removeChip}>
                &times;
            </span>
        </div>
    );
}

export default Chip;