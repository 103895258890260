function Table({ fields, fieldItems }) {

    if (!fields || fields.length === 0) {
        return <div>No data to display!</div>
    }

    return (
        <>
            <div className="user_items">
                <>
                    <span>ID</span>
                    {fields.map((x, i) => (
                        <span>{x.label}</span>
                    ))}
                    {fieldItems && fieldItems.length > 0 && fieldItems.map(x => (
                        <>
                            <span>{x.id}</span>
                            {fields.map(y => (
                                <span>{x.value[y.fieldName]}</span>
                            ))}
                        </>
                    ))}
                </>
            </div>
            {fieldItems && fieldItems.length === 0 && (
                <span class="no_data"> No data to display </span>
            )}
        </>
    );
}

export default Table;