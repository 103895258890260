import { DATABASE_NAME } from '../scripts/constants';

export const getDatabase = (tableName, callback) => {
    let db, tx, store;
    let request = window.indexedDB.open(DATABASE_NAME, 1);

    request.onupgradeneeded = (e) => {
        let db = request.result;
        db.createObjectStore(tableName, { keyPath: "id", autoIncrement:true });
    };

    request.onerror = (e) => {
        console.log("error" + e.target.errorCode);
        callback("error");
    };

    request.onsuccess = (e) => {
        db = request.result;
        tx = db.transaction(tableName, "readonly");
        store = tx.objectStore(tableName);

        db.onerror = (e) => {
            console.log("error");
        };

        store.getAll().onsuccess = (e) => {
            callback(e.target.result);
        };

        tx.oncomplete = () => {
            db.close();
        };
    };
};

export const putDatabase = (tableName, tableData, callback) => {
    let db, tx, store;
    let request = window.indexedDB.open(DATABASE_NAME, 1);

    request.onupgradeneeded = (e) => {
        let db = request.result;
        db.createObjectStore(tableName, { keyPath: "uid" });
    };

    request.onerror = (e) => {
        console.log("error" + e.target.errorCode);
    };

    request.onsuccess = (e) => {
        db = request.result;
        tx = db.transaction(tableName, "readwrite");
        store = tx.objectStore(tableName);

        db.onerror = (e) => {
            console.log("error" + e.target.errorCode);
            callback("error");
        };

        // tableData = JSON.stringify(tableData);
        store.put({value: tableData});
        tx.oncomplete = () => {
            db.close();
            callback("success");
        };
    };
};