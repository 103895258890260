import { useRef, useState, useEffect, useCallback } from "react";

import Chip from "./components/Chip";
import Table from "./components/Table";

import useDebounce from './utils/useDebounce';
import { getDatabase, putDatabase } from './utils/indexDB';
import { FIELDS } from './scripts/constants';
import { getWordsSpellLike } from "./scripts/services";
import "./App.css";

function App() {
    const fieldRefs = useRef({});
    const [userList, setUserList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [suggestion, setSuggestion] = useState([]);
    const [hobby, setHobby] = useState([]);
    const [submit, setSubmit] = useState(false);
    const debSearchTerm = useDebounce(searchTerm, 500);

    const loadUsersList = () => {
        getDatabase("userDetails", (results) => {
            if (results !== "error") {
                setUserList(results);
            }
        });
    };

    useEffect(loadUsersList, []);

    useEffect(() => {
        for (let i in fieldRefs.current) {
            if (fieldRefs.current.hasOwnProperty(i)) {
                fieldRefs.current[i].value = "";
            }
        }
        setSearchTerm("");
        setSuggestion([]);
        setHobby([]);
        loadUsersList();
    }, [submit]);

    useEffect(() => {
        if (debSearchTerm) {
            async function getSuggestion() {
                const results = await getWordsSpellLike(debSearchTerm);
                setSuggestion(results);
            }
            getSuggestion();
        }
    }, [debSearchTerm]);

    const showFieldValueError = (ref) => {
        var ele = ref.nextElementSibling;
        ele.style.display = "block";
        setTimeout(() => {
            ele.style.display = "none";
        }, 3000);
    };

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const isValidValues = () => {
            if (fieldRefs.current['name'].value === "") {
                showFieldValueError(fieldRefs.current['name']);
                return false;
            }
            if (fieldRefs.current['dob'].value === "") {
                showFieldValueError(fieldRefs.current['dob']);
                return false;
            }
            const dob = new Date(fieldRefs.current['dob'].value).getTime();
            const dateNow = new Date();
            if (!(dob < dateNow.getTime() && dob > dateNow.setFullYear(1920))) {
                showFieldValueError(fieldRefs.current['dob']);
                return false;
            }
            return true;
        };
        if (!isValidValues()) {
            return false;
        }
        const newUser = {
            name: fieldRefs.current['name'].value,
            gender: fieldRefs.current['gender'].value,
            dob: fieldRefs.current['dob'].value,
            hobby: hobby.join(", "),
            subscribed: fieldRefs.current['subscribed'].checked ? "Yes" : "No",
        };
        putDatabase("userDetails", newUser, (results) => {
            if (results !== "error") {
                setSubmit(!submit);
            }
        });
    }, [hobby, submit]);

    const handleChange = (e) => {
        e.target.value.length >= 3 && setSearchTerm(e.target.value);
    };

    const handleKeyDown = useCallback((e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const newVal = fieldRefs.current['hobby'].value;
            const newArr = [...hobby];
            const pos = newArr.indexOf(newVal);
            if (pos === -1) {
                newArr.push(newVal);
                setHobby(newArr);
            }
            fieldRefs.current['hobby'].value = "";
        }
    }, [hobby]);

    const removeChip = useCallback((e) => {
        const item = e.target.previousElementSibling.innerText;
        const newArr = [...hobby];
        const pos = newArr.indexOf(item);
        if (pos !== -1) {
            newArr.splice(pos, 1);
            setHobby(newArr);
        }
    }, [hobby]);


    return (
        <div className="App">
            <div className="add_user">
                <h2>Add User</h2>
                {FIELDS.map((item, index) => (
                    <div key={item.fieldName + '_' + index} className={"field "+item.fieldClass}>
                        <label htmlFor={item.fieldName}>{item.label}</label>
                        {item.field === "input" && (
                            <>
                                {item.autosuggest && item.multipleValue ? (
                                    <>
                                        <div className="chips_block">
                                            {hobby && hobby.length > 0 && hobby.map((x, i) =>
                                                <Chip key={x + '_' + i} item={x} removeChip={removeChip} />
                                            )}
                                        </div>
                                        <input
                                            type={item.fieldType}
                                            name={item.fieldName}
                                            list={item.autosuggest && item.fieldName + '_list'}
                                            ref={el => fieldRefs.current[item.fieldName] = el}
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="error">Invalid Value</div>
                                        <datalist id={item.fieldName + '_list'}>
                                            {suggestion && suggestion.length > 0 && suggestion.map((x, i) =>
                                                <option key={x.word + '_' + i} value={x.word}></option>
                                            )}
                                        </datalist>
                                    </>
                                ) : (
                                    <>
                                        <input
                                            type={item.fieldType}
                                            name={item.fieldName}
                                            ref={el => fieldRefs.current[item.fieldName] = el}
                                        />
                                        <div className="error">Invalid Value</div>
                                    </>
                                )}
                            </>
                        )}
                        {item.field === "select" && (
                            <select ref={el => fieldRefs.current[item.fieldName] = el}>
                                {item.options && item.options.length > 0 && item.options.map((x, i) =>
                                    <option key={x + '_' + i}>{x}</option>
                                )}
                            </select>
                        )}
                    </div>
                ))}
                <div className="field">
                    <input type="submit" value="Submit" onClick={handleSubmit} />
                </div>
            </div>
            <div className="user_list">
                <h2>Users List</h2>
                <Table fields={FIELDS} fieldItems={userList} />
            </div>
        </div>
    );
}

export default App;
