
export const DATAMUSE_API = "https://api.datamuse.com/words";
export const DATABASE_NAME = "MyDatabase";
export const FIELDS = [
  {
    field: "input",
    fieldType: "text",
    fieldName: "name",
    label: "Name",
    order: 1
  },
  {
    field: "select",
    fieldName: "gender",
    label: "Gender",
    options: [
      "Male",
      "Female",
      "Prefer not to say",
      "Other",
    ],
    order: 2
  },
  {
    field: "input",
    fieldType: "date",
    fieldName: "dob",
    label: "DOB",
    order: 3
  },
  {
    field: "input",
    fieldType: "text",
    fieldName: "hobby",
    label: "Hobbies/Interest",
    multipleValue: true,
    autosuggest: true,
    order: 4
  },
  {
    field: "input",
    fieldType: "checkbox",
    fieldClass: "row",
    fieldName: "subscribed",
    label: "Subscribe to daily/weekly updates.",
    order: 5
  },
];